import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { JSONSchema, JSONSchemaType } from '@makeropsinc/workflow-types';
import { Tab, Tabs } from './tab';
import { createValueFromSchemaType, validateObject } from './json-schemas';
import { comparePaths, parsePath } from './utils';
import { TextInputProps } from './types';
import { Property } from './property';
import { JSONEditor } from './json-editor';

type TabType = 'form' | 'json';

interface Props {
  title?: string;
  tabLabel?: (type: TabType) => string;
  schema: JSONSchema;
  value?: JSONSchemaType;
  onChange: (arg0: JSONSchemaType) => void;
  children?: (props: TextInputProps & { schema: JSONSchema }) => ReactNode;
  validation?: (arg0: () => boolean) => void;
  hideJSONEditor?: boolean;
  maxLevel?: number;
  noSelects?: boolean;
}

const defaultTabLabel: Props['tabLabel'] = (tab) => {
  switch (tab) {
    case 'form':
      return 'Key value';
    case 'json':
      return 'JSON';
  }
};

export interface ValidationError {
  message: string;
  path: string;
}

export interface ContextType {
  schema: JSONSchema;
  value: JSONSchemaType;
  validate: (path?: string) => boolean;
  getErrors: (path?: string) => ValidationError[];
  setErrors: (path: string, errors: ValidationError[]) => void;
  clearErrors: (path?: string) => void;
  renderTextField?: (
    props: TextInputProps & { schema: JSONSchema }
  ) => ReactNode;
}

export const context = createContext<ContextType>({
  schema: {},
  value: null,
  validate: () => false,
  getErrors: () => [],
  setErrors: () => undefined,
  clearErrors: () => undefined,
  renderTextField: () => null,
});


export const FormFromSchema: React.FC<Props> = ({
  title = 'Schema',
  tabLabel = defaultTabLabel,
  schema,
  value,
  onChange,
  validation,
  children,
  hideJSONEditor = false,
  maxLevel = Number.MAX_SAFE_INTEGER,
  noSelects = false,
}) => {
  const [tab, setTab] = useState('form' as TabType);
  const [errors, setErrors] = useState([] as ValidationError[]);
  const handleTabChange = useCallback(
    (_: any, tab: TabType) => setTab(tab),
    []
  );
  const handleChange = useCallback(
    (_: any, schema: JSONSchemaType) => onChange(schema),
    [onChange]
  );

  const getErrors = useCallback(
    (path?: string) => {
      if (!path) return errors;
      return errors.filter((e) => comparePaths(e.path, path));
    },
    [errors]
  );

  const setErrorsCb = useCallback((path: string, errs: ValidationError[]) => {
    if (!path) return setErrors(errs);

    return setErrors((oldErrs) =>
      oldErrs
        .filter((e) => !e.path?.startsWith(parsePath(path)))
        .concat(...errs)
    );
  }, []);

  const clearErrors = useCallback((path?: string) => {
    if (!path) return setErrors([]);
    setErrors((errs) =>
      errs.filter((e) => !e.path?.startsWith(parsePath(path)))
    );
  }, []);

  const validate = useCallback(() => {
    const validation = validateObject(value ?? {}, schema, true);
    setErrors(validation.errors.map((e) => ({ ...e, path: e.dataPath ?? '' })));
    return validation.isValid;
  }, [value, schema]);

  useEffect(() => validation && validation(validate), [validation, validate]);

  useEffect(() => {
    if (!value) {
      onChange(createValueFromSchemaType(schema, schema) as JSONSchemaType);
    }
  }, [schema, value, onChange]);

  if (!value) return null;

  return (
    <>
      {!hideJSONEditor && (
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab value="form" label={tabLabel('form')} />
          <Tab value="json" label={tabLabel('json')} />
        </Tabs>
      )}
      <context.Provider
        value={{
          schema,
          value,
          validate,
          getErrors,
          setErrors: setErrorsCb,
          clearErrors,
          renderTextField: children,
        }}
      >
        {(hideJSONEditor || tab === 'form') && (
          <Property
            path="/"
            propName={title}
            property={schema}
            value={value}
            onChange={handleChange}
            level={0}
            maxLevel={maxLevel}
            noSelects={noSelects}
          />
        )}
        {!hideJSONEditor && tab === 'json' && (
          <JSONEditor value={value} schema={schema} onChange={onChange} />
        )}
      </context.Provider>
    </>
  );
};
