import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { TagField, useTags } from "@/components/custom/tag-input";
import { Textarea } from "@/components/cataylst/textarea";
import { MultiTextGroup } from "@/components/custom/multi-text-group";
import { useParams } from "react-router-dom";
import { IPersona } from "./DemoPersonasPage";
import { useEffect, useState } from "react";
import { apiClient, localApi } from "@/services/api";
import { Environment } from "@/utils/constants";
import axios from "axios";

export const emptyPersona: IPersona = {
  personaId: '',
  id: '',
  name: '',
  commonJobTitles: '',
  description: '',
  icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#00B453"/>
       <path fill-rule="evenodd" clip-rule="evenodd" d="M16 10.75C13.1005 10.75 10.75 13.1005 10.75 16C10.75 17.3498 11.2594 18.5806 12.0964 19.5107C12.7023 18.7279 13.6455 18.25 14.6667 18.25H17.3333C18.3545 18.25 19.2976 18.7279 19.9036 19.5107C20.7406 18.5806 21.25 17.3498 21.25 16C21.25 13.1005 18.8995 10.75 16 10.75ZM18.7503 20.4728C18.4259 20.0253 17.902 19.75 17.3333 19.75H14.6667C14.0979 19.75 13.5741 20.0253 13.2497 20.4728C14.0495 20.9657 14.9916 21.25 16 21.25C17.0084 21.25 17.9504 20.9657 18.7503 20.4728ZM9.25 16C9.25 12.2721 12.2721 9.25 16 9.25C19.7279 9.25 22.75 12.2721 22.75 16C22.75 19.7279 19.7279 22.75 16 22.75C12.2721 22.75 9.25 19.7279 9.25 16ZM16 13.4167C15.3096 13.4167 14.75 13.9763 14.75 14.6667C14.75 15.357 15.3096 15.9167 16 15.9167C16.6904 15.9167 17.25 15.357 17.25 14.6667C17.25 13.9763 16.6904 13.4167 16 13.4167ZM13.25 14.6667C13.25 13.1479 14.4812 11.9167 16 11.9167C17.5188 11.9167 18.75 13.1479 18.75 14.6667C18.75 16.1855 17.5188 17.4167 16 17.4167C14.4812 17.4167 13.25 16.1855 13.25 14.6667Z" fill="white"/>
       </svg>,
  keyConcerns: '',
  keyObjectives: '',
  messagingExamples: '',
  painPoints: '',
  responsibilities: ''
};

export default function DemoPersonaDetailpage() {
  const [personaDetails, setPersonaDetails] = useState<IPersona>(emptyPersona);
  const {
    tags: tagKeywords,
    handleAddTag: handleAddTagKeyword,
    handleRemoveTag: handleRemoveTagKeyword,
  } = useTags(5, personaDetails.commonJobTitles);
  
  const { personaId } = useParams();

  useEffect(() => {
    localApi.get(`/agents/orgs/x-functions/knowledgebase-context/personas/${personaId}`)
      .then(res => {
        setPersonaDetails({
          ...res.data,
          icon: emptyPersona.icon
        });
      });
  }, [personaId]);

  const onPersonaDetailsSingleChange = (fieldName: string, texts: string) => {
    const newPersonaDetails = { ...personaDetails };
    newPersonaDetails[fieldName] = texts;
    setPersonaDetails(newPersonaDetails);
  };

  const onAddKnowledgebaseFile = async () => {
    const newPersonaDetails: IPersona = {
      ...personaDetails,
      commonJobTitles: tagKeywords.join("@@@")
    }
    if (!personaDetails.id) {
      const { data } = await localApi.post(`/agents/orgs/x-functions/knowledgebase-context/personas`, newPersonaDetails);
      setPersonaDetails({
        ...data,
        icon: emptyPersona.icon
      });
    }
    else
      await localApi.put(`/agents/orgs/x-functions/knowledgebase-context/personas`, newPersonaDetails);
    const { data: newKnowledgebaseFile } = await apiClient.post(
      `/agents/orgs/x-functions/knowledgebases/${Environment.KNOWLEDGE_BASE_ID}/files/upload-url`,
      {
        fileName: `New-Persona-Knowledgebase-File-${new Date().getTime()}.txt`,
        contentType: "text",
      }
    );
    const filePresignedUrl = newKnowledgebaseFile.uploadUrl;
    await axios.put(
      filePresignedUrl,
      JSON.stringify({ ...personaDetails, icon: undefined }),
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
  };

  return (
    <main>
      <MainSidebar
        pageTitle={`Personas/${personaDetails.name || "New"}`}
        // shouldHideActionButton
        toolbar={
          <Button color="dark" size="lg" onClick={onAddKnowledgebaseFile}>
            Save
          </Button>
        }
      >
        <div className="text-cell px-8 py-5 max-w-[852px]">
          <div className="flex flex-col gap-2">
            <div className="font-medium">Persona name</div>
            <Textarea
              resizable={false}
              rows={1}
              withItems={null}
              type="text"
              value={personaDetails?.name}
              onChange={(e) => {
                onPersonaDetailsSingleChange("name", e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Description</div>
            <Textarea
              resizable={false}
              withItems={null}
              type="text"
              value={personaDetails?.description}
              rows={4}
              onChange={(e) => {
                onPersonaDetailsSingleChange("description", e.target.value);
              }}
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add responsibility"
              label={"Primary responsibilities"}
              defaultValues={personaDetails?.responsibilities.split("@@@")}
              onChange={(texts: string[]) =>
                onPersonaDetailsSingleChange("responsibilities", texts.join("@@@"))
              }
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add pain point"
              label={"Pain points"}
              defaultValues={personaDetails?.painPoints.split("@@@")}
              onChange={(texts: string[]) =>
                onPersonaDetailsSingleChange("painPoints", texts.join("@@@"))
              }
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add concern"
              label={"Concerns"}
              defaultValues={personaDetails?.keyConcerns.split("@@@")}
              onChange={(texts: string[]) =>
                onPersonaDetailsSingleChange("keyConcerns", texts.join("@@@"))
              }
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add objective"
              label={"Objectives"}
              defaultValues={personaDetails?.keyObjectives.split("@@@")}
              onChange={(texts: string[]) =>
                onPersonaDetailsSingleChange("keyObjectives", texts.join("@@@"))
              }
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Common job titles</div>
            <TagField
              tags={tagKeywords}
              addTag={handleAddTagKeyword}
              removeTag={handleRemoveTagKeyword}
              maxTags={5}
              color="primary"
              placeholder="Type to add titles..."
            />
          </div>
          <div className="mt-20"></div>
        </div>
      </MainSidebar>
    </main>
  );
}
