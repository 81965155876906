import { Environment } from "@/utils/constants";
import { BloomStorage } from "@/utils/storage";
import axios from "axios";
import { v4 } from "uuid";

export const apiClient = axios.create({
  baseURL: Environment.API_URL
});

apiClient.interceptors.request.use((config) => {
  const accessToken = BloomStorage.getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

export const fetcher = (url: string) => {
  return apiClient.get(url).then(res => res.data);
};

export const getDataFromLocalStorage = (key: string) => {
  const value = localStorage.getItem(key) || '';
  try {
    return JSON.parse(value);
  } catch (err) {
    return null;
  }
};

export const localApi = {
  write: async (url: string, body: any) => {
    const [category] = url.split("/").reverse();
    let items = getDataFromLocalStorage(`knowledgebase_${category}`) || [];
    if (!items.length) {
      items = [body];
    } else {
      items[0] = body;
    }
    localStorage.setItem(`knowledgebase_${category}`, JSON.stringify(items));
    console.log("@Write: ", { category, items });
    return body;
  },
  post: async (url: string, body: any) => {
    const [category] = url.split("/").reverse();
    const items = getDataFromLocalStorage(`knowledgebase_${category}`) || [];
    body['id'] = v4();
    items.push(body);
    localStorage.setItem(`knowledgebase_${category}`, JSON.stringify(items));
    return { data: body };
  },
  put: async (url: string, body: any) => {
    const [category] = url.split("/").reverse();
    const items = getDataFromLocalStorage(`knowledgebase_${category}`) || [];
    const index = items.findIndex(item => item.id === body.id);
    if (index >= 0) {
      items[index] = body;
      localStorage.setItem(`knowledgebase_${category}`, JSON.stringify(items));
      return { data: body };
    } else {
      throw Error("Not found");
    }
  },
  list: async (url: string) => {
    const [category] = url.split("/").reverse();
    const items = getDataFromLocalStorage(`knowledgebase_${category}`) || [];
    return { data: { items } };
  },
  get: async (url: string) => {
    const [id, category] = url.split("/").reverse();
    const items = getDataFromLocalStorage(`knowledgebase_${category}`) || [];
    const item = items.find(item => item.id === id);
    if (!item) {
      throw Error("Not found");
    }
    return { data: item };
  }
}