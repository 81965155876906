import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { TagField, useTags } from "@/components/custom/tag-input";
import { Textarea } from "@/components/cataylst/textarea";
import { MultiTextGroup } from "@/components/custom/multi-text-group";
import { useParams } from "react-router-dom";
import { IUseCase } from "./DemoUseCasesPage";
import { useEffect, useState } from "react";
import { apiClient, localApi } from "@/services/api";
import { Environment } from "@/utils/constants";
import axios from "axios";

export const emptyUsecase: IUseCase = {
  id: '',
  useCaseId: '',
  challenges: '',
  description: '',
  desiredOutcomes: '',
  icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#106EF6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.75 10C16.75 9.58579 16.4142 9.25 16 9.25C15.5858 9.25 15.25 9.58579 15.25 10V12C15.25 12.4142 15.5858 12.75 16 12.75C16.4142 12.75 16.75 12.4142 16.75 12V10ZM10 15.25C9.58579 15.25 9.25 15.5858 9.25 16C9.25 16.4142 9.58579 16.75 10 16.75H12C12.4142 16.75 12.75 16.4142 12.75 16C12.75 15.5858 12.4142 15.25 12 15.25H10ZM11.203 11.203C11.4959 10.9101 11.9708 10.9101 12.2637 11.203L13.7303 12.6697C14.0232 12.9626 14.0232 13.4374 13.7303 13.7303C13.4374 14.0232 12.9626 14.0232 12.6697 13.7303L11.203 12.2637C10.9101 11.9708 10.9101 11.4959 11.203 11.203ZM20.797 12.2637C21.0899 11.9708 21.0899 11.4959 20.797 11.203C20.5041 10.9101 20.0292 10.9101 19.7363 11.203L18.2697 12.6697C17.9768 12.9626 17.9768 13.4374 18.2697 13.7303C18.5626 14.0232 19.0374 14.0232 19.3303 13.7303L20.797 12.2637ZM13.7303 18.2697C14.0232 18.5626 14.0232 19.0374 13.7303 19.3303L12.2637 20.797C11.9708 21.0899 11.4959 21.0899 11.203 20.797C10.9101 20.5041 10.9101 20.0292 11.203 19.7363L12.6697 18.2697C12.9626 17.9768 13.4374 17.9768 13.7303 18.2697ZM16.2372 15.2885C15.9677 15.1986 15.6706 15.2688 15.4697 15.4697C15.2688 15.6705 15.1987 15.9677 15.2885 16.2372L17.2885 22.2372C17.3844 22.5249 17.6443 22.7266 17.9469 22.7481C18.2494 22.7696 18.5352 22.6067 18.6708 22.3354L19.8924 19.8923L22.3354 18.6708C22.6068 18.5351 22.7696 18.2494 22.7481 17.9468C22.7266 17.6442 22.525 17.3844 22.2372 17.2885L16.2372 15.2885ZM18.139 20.0451L17.1859 17.1858L20.0451 18.1389L18.9979 18.6625C18.8528 18.7351 18.7351 18.8528 18.6625 18.9979L18.139 20.0451Z" fill="white"/>
       </svg>,
      name: '',
      relatedProof: ''
}

export default function DemoUseCaseDetailpage() {
  const { usecaseId } = useParams();

  const [usecaseDetails, setUsecaseDetails] = useState<IUseCase>(emptyUsecase);

  useEffect(() => {
    localApi.get(`/agents/orgs/x-functions/knowledgebase-context/usecases/${usecaseId}`)
      .then(res => {
        setUsecaseDetails({
          ...res.data,
          icon: emptyUsecase.icon
        });
      });
  }, [usecaseId]);

  const onUsecaseDetailsSingleChange = (fieldName: string, texts: string) => {
    const newUsecaseDetails = { ...usecaseDetails };
    newUsecaseDetails[fieldName] = texts;
    setUsecaseDetails(newUsecaseDetails);
  };

  const onAddKnowledgebaseFile = async () => {
    const newUsecaseDetails: IUseCase = {
      ...usecaseDetails
    }
    if (!usecaseDetails.id) {
      const { data } = await localApi.post(`/agents/orgs/x-functions/knowledgebase-context/usecases`, newUsecaseDetails);
      setUsecaseDetails({
        ...data,
        icon: emptyUsecase.icon
      });
    }
    else
      await localApi.put(`/agents/orgs/x-functions/knowledgebase-context/usecases`, newUsecaseDetails);
    const { data: newKnowledgebaseFile } = await apiClient.post(`/agents/orgs/x-functions/knowledgebases/${Environment.KNOWLEDGE_BASE_ID}/files/upload-url`, {
      fileName: `New-Usecase-Knowledgebase-File-${new Date().getTime()}.txt`,
      contentType: 'text'
    });
    const filePresignedUrl = newKnowledgebaseFile.uploadUrl;
    await axios.put(filePresignedUrl, JSON.stringify({...usecaseDetails, icon: undefined}), {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
  }

  return (
    <main>
      <MainSidebar
        pageTitle={`UseCases/${usecaseDetails.name || 'New'}`}
        // shouldHideActionButton
        toolbar={
          <Button color="dark" size="lg" onClick={onAddKnowledgebaseFile}>
            Save
          </Button>
        }
      >
        <div className="text-cell px-8 py-5 max-w-[852px]">
          <div className="flex flex-col gap-2">
            <div className="font-medium">Use case name</div>
            <Textarea
              resizable={false}
              rows={1}
              withItems={null}
              type="text"
              value={usecaseDetails?.name}
              onChange={(e) => {
                onUsecaseDetailsSingleChange("name", e.target.value)
              }}
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Description</div>
            <Textarea
              resizable={false}
              withItems={null}
              type="text"
              value={usecaseDetails?.description}
              rows={4}
              onChange={(e) => {
                onUsecaseDetailsSingleChange("description", e.target.value)
              }}
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup buttonName="Add challenge" label={"Challenges"} defaultValues={usecaseDetails?.challenges.split("@@@")} onChange={(texts: string[]) =>
                onUsecaseDetailsSingleChange("challenges", texts.join("@@@"))
              } />
          </div>
          <div className="mt-8">
            <MultiTextGroup buttonName="Add outcome" label={"Desired outcomes"} defaultValues={usecaseDetails?.desiredOutcomes.split("@@@")} onChange={(texts: string[]) =>
                onUsecaseDetailsSingleChange("desiredOutcomes", texts.join("@@@"))
              } />
          </div>
          <div className="mt-20">

          </div>
        </div>
      </MainSidebar>
    </main>
  );
}
