import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { TagField, useTags } from "@/components/custom/tag-input";
import { Select } from "@/components/cataylst/select";
import { Textarea } from "@/components/cataylst/textarea";
import { MultiTextGroup } from "@/components/custom/multi-text-group";
import { useParams } from "react-router-dom";
import { IProduct, mockProducts } from "./DemoProductsPage";
import { useCallback, useEffect, useState } from "react";
import { apiClient, localApi } from "@/services/api";
import { Environment } from "@/utils/constants";
import axios from "axios";

export const emptyProduct: IProduct = {
  productId: '',
  id: '',
  name: '',
  description: '',
  currentChallenges: '',
  valuePropositions: '',
  features: '',
  customerBenefits: '',
  idealCustomerProfile: '',
  documentationPath: '',
  integrationCapabilities: '',
  icon: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#EE2E5C"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 11.4167C11.8454 11.4167 11.697 11.4781 11.5876 11.5875C11.4782 11.6969 11.4167 11.8453 11.4167 12V12.5833H12.5834V11.4167H12.0001ZM13.3334 9.91666H20.0001C20.5526 9.91666 21.0825 10.1361 21.4732 10.5269C21.8639 10.9176 22.0834 11.4475 22.0834 12V13.3333V20C22.0834 20.5525 21.8639 21.0824 21.4732 21.4731C21.0825 21.8638 20.5526 22.0833 20.0001 22.0833H12.0001C11.4475 22.0833 10.9176 21.8638 10.5269 21.4731C10.1362 21.0824 9.91675 20.5525 9.91675 20V13.3333V12C9.91675 11.4475 10.1362 10.9176 10.5269 10.5269C10.9176 10.1361 11.4475 9.91666 12.0001 9.91666H13.3334ZM14.0834 11.4167V12.5833H20.5834V12C20.5834 11.8453 20.522 11.6969 20.4126 11.5875C20.3032 11.4781 20.1548 11.4167 20.0001 11.4167H14.0834ZM20.5834 20V14.0833H11.4167V20C11.4167 20.1547 11.4782 20.3031 11.5876 20.4125C11.697 20.5219 11.8454 20.5833 12.0001 20.5833H20.0001C20.1548 20.5833 20.3032 20.5219 20.4126 20.4125C20.522 20.3031 20.5834 20.1547 20.5834 20ZM12.2501 18.6633C12.2501 18.2491 12.5859 17.9133 13.0001 17.9133H15.6667C16.081 17.9133 16.4167 18.2491 16.4167 18.6633C16.4167 19.0775 16.081 19.4133 15.6667 19.4133H13.0001C12.5859 19.4133 12.2501 19.0775 12.2501 18.6633ZM13.0001 15.58C12.5859 15.58 12.2501 15.9158 12.2501 16.33C12.2501 16.7442 12.5859 17.08 13.0001 17.08H17.0001C17.4143 17.08 17.7501 16.7442 17.7501 16.33C17.7501 15.9158 17.4143 15.58 17.0001 15.58H13.0001Z" fill="white"/>
    </svg>
  )
}

export default function DemoProductDetailPage() {
  const [productDetails, setProductDetails] = useState<IProduct>(emptyProduct);
  const {
    tags: tagKeywords,
    handleAddTag: handleAddTagKeyword,
    handleRemoveTag: handleRemoveTagKeyword,
  } = useTags(5, productDetails.integrationCapabilities);
  const {
    tags: tagJargons,
    handleAddTag: handleAddTagJargon,
    handleRemoveTag: handleRemoveTagJargon,
  } = useTags(5, productDetails.idealCustomerProfile);

  const { productId } = useParams();

  useEffect(() => {
    apiClient.get(`/agents/orgs/x-functions/knowledgebase-context/products/${productId}`)
      .then(res => {
        setProductDetails({
          ...res.data,
          icon: emptyProduct.icon
        });
      });
  }, [productId]);

  const onProductDetailsSingleChange = (fieldName: string, texts: string) => {
    const newProductDetails = { ...productDetails };
    newProductDetails[fieldName] = texts;
    setProductDetails(newProductDetails);
  };

  const onAddKnowledgebaseFile = async () => {
    const newProductDetails: IProduct = {
      ...productDetails,
      idealCustomerProfile: tagJargons.join("@@@"),
      integrationCapabilities: tagKeywords.join("@@@"),
    }
    if (!productDetails.id) {
      const { data } = await apiClient.post(`/agents/orgs/x-functions/knowledgebase-context/products`, newProductDetails);
      setProductDetails({
        ...data,
        icon: emptyProduct.icon
      });
    }
    else
      await apiClient.put(`/agents/orgs/x-functions/knowledgebase-context/products`, newProductDetails);
    const { data: newKnowledgebaseFile } = await apiClient.post(`/agents/orgs/x-functions/knowledgebases/${Environment.KNOWLEDGE_BASE_ID}/files/upload-url`, {
      fileName: `New-Product-Knowledgebase-File-${new Date().getTime()}.txt`,
      contentType: 'text'
    });
    const filePresignedUrl = newKnowledgebaseFile.uploadUrl;
    await axios.put(filePresignedUrl, JSON.stringify({...productDetails, icon: undefined}), {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
  }

  return (
    <main>
      <MainSidebar
        pageTitle={`Products/${productDetails.name || "New"}`}
        // shouldHideActionButton
        toolbar={
          <Button color="dark" size="lg" onClick={onAddKnowledgebaseFile}>
            Save
          </Button>
        }
      >
        <div className="text-cell px-8 py-5 max-w-[852px]">
          <div className="flex flex-col gap-2">
            <div className="font-medium">Product name</div>
            <Textarea
              resizable={false}
              rows={1}
              withItems={null}
              type="text"
              value={productDetails?.name}
              onChange={(e) => {
                onProductDetailsSingleChange("name", e.target.value)
              }}
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Description</div>
            <Textarea
              resizable={false}
              withItems={null}
              type="text"
              value={productDetails?.description}
              rows={4}
              onChange={(e) => {
                onProductDetailsSingleChange("description", e.target.value)
              }}
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add challenge"
              label={"Challenges - status quo"}
              defaultValues={productDetails?.currentChallenges.split("@@@")}
              onChange={(texts: string[]) =>
                onProductDetailsSingleChange("currentChallenges", texts.join("@@@"))
              }
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add value prop"
              label={"Differentiated value"}
              defaultValues={productDetails?.valuePropositions.split("@@@")}
              onChange={(texts: string[]) =>
                onProductDetailsSingleChange("valuePropositions", texts.join("@@@"))
              }
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add capability"
              label={"Capabilities"}
              defaultValues={productDetails?.features.split("@@@")}
              onChange={(texts: string[]) =>
                onProductDetailsSingleChange("features", texts.join("@@@"))
              }
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup
              buttonName="Add benefit"
              label={"Customer benefits"}
              defaultValues={productDetails?.customerBenefits.split("@@@")}
              onChange={(texts: string[]) =>
                onProductDetailsSingleChange("customerBenefits", texts.join("@@@"))
              }
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Integration capabilities</div>
            <TagField
              tags={tagKeywords}
              addTag={handleAddTagKeyword}
              removeTag={handleRemoveTagKeyword}
              maxTags={5}
              color="success"
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Product ICP</div>
            <TagField
              tags={tagJargons}
              addTag={handleAddTagJargon}
              removeTag={handleRemoveTagJargon}
              maxTags={5}
              color="primary"
            />
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Product documentation</div>
            <div className="w-full border border-[#106EF6] border-dashed rounded-lg h-[64px] bg-[#F8FAFE] flex items-center justify-center text-sm cursor-pointer">
              Drag here to upload logo, or&nbsp;
              <span className="underline">browse files</span>
            </div>
          </div>
          <div className="mt-20"></div>
        </div>
      </MainSidebar>
    </main>
  );
}
