import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { Textarea } from "@/components/cataylst/textarea";
import { PlusIcon, SearchIcon } from "@/components/custom/icons";
import { Input, InputGroup } from "@/components/cataylst/input";
import { MenuIcon } from "@/components/custom/menu-icon";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { localApi } from "@/services/api";
import { emptyCompetitor } from "./DemoCompetitorDetailPage";

export interface ICompetitor {
  id?: string;
  competitorId: string;
  name: string;
  website: string;
  valuePropositions: string;
  weaknesses: string;
  differentiation: string;
  icon: ReactNode;
}

interface ICompetitorItemProps {
  competitor: ICompetitor;
  onClick?: Function;
  borderTop?: boolean;
}

function CompetitorItem({ competitor: { name, website, icon }, borderTop, onClick }: ICompetitorItemProps) {
  return (
    <div className={`flex items-center justify-between pr-2 py-4 font-firstext ${borderTop && 'border-t border-solid border-t-gray-200'} border-b border-b-gray-200 cursor-pointer`} onClick={() => ((onClick || (() => {})))()}>
      <div className="flex gap-3 items-center w-[90%]">
        <div className="flex-grow-[32px]">
          {icon}
        </div>
        <div className="flex flex-col gap-1 flex-1 max-w-full">
          <div className="font-semibold text-sm leading-[17.5px]">
            {name}
          </div>
          <div className="text-sm leading-[21px] opacity-60 pr-10 truncate">
            {website}
          </div>
        </div>
      </div>
      <div>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.96967 3.46967C6.26256 3.17678 6.73744 3.17678 7.03033 3.46967L11.0303 7.46967C11.3232 7.76256 11.3232 8.23744 11.0303 8.53033L7.03033 12.5303C6.73744 12.8232 6.26256 12.8232 5.96967 12.5303C5.67678 12.2374 5.67678 11.7626 5.96967 11.4697L9.43934 8L5.96967 4.53033C5.67678 4.23744 5.67678 3.76256 5.96967 3.46967Z" fill="black" fill-opacity="0.4"/>
        </svg>
      </div>
    </div>
  )
}

// export const mockCompetitors: ICompetitor[] = [
//   {
//     id: 1,
//     title: 'Salesforce',
//     website: `salesforce.com`,
//     icon: <img src='https://s3-alpha-sig.figma.com/img/716d/368d/533c1c6938b05acca2a75837df8e8546?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=LBDCenffa~np866xY9DL0l9kKG-y9qWnATfeAj5tbVWAKtt-zKOMqWCPztOPo9wqMDssjpZv7IvassBJAaymaUs254ySFQM3BrD~7K~2pNx-bMYaKwNa4ew5-YgJGdUsdMyOJ3Z7Qu71O9NEQh0KSIno9XsscipSr6Fwyj9YqECE--qo4FsxXpJlOOCSc8HMncdb-JsX5oM2FjQPqco5iBVR6Oy6Rj~apJJeV-3sZeBLPVQGmMo34JiM7aVWg88czkdFd1CqZEsKa333imSWfquBUBZL1BoTjPgJFKFGJFwVL1ysvYNaPMlgS7UXzHNJp~SNv4gwPZTQj9~9SvX-wg__' className="size-8" />,
//     competitorValueProps: [
//       `AI-Driven Content Creation: Copy.ai automates the creation of personalized marketing and sales copy, helping teams quickly generate engaging content for emails, blogs, and social media.`,
//       `User-Friendly Interface: The platform is easy to use, allowing even non-technical users to create professional copy quickly.`,
//       `Specialized for Content: Copy.ai’s algorithms are optimized for generating creative, human-like marketing copy, positioning it as a key tool for content-heavy teams`
//     ],
//     competitorWeaknesses: [
//       `Limited Integration with broader GTM workflows, making it less suitable for teams needing full sales cycle automation.`,
//       `Focused on Content Only: Lacks CRM or GTM process management capabilities`
//     ],
//     differentiates: [
//       `Bloom offers end-to-end GTM automation with full CRM integration, covering the entire sales cycle rather than focusing solely on content creation.`,
//       `Bloom's advanced AI supports content generation but also integrates with sales automation tools, allowing for deeper GTM automation.`
//     ]
//   },
//   {
//     id: 2,
//     title: 'Copy.ai',
//     website: `copy.ai`,
//     icon: <img src='https://s3-alpha-sig.figma.com/img/b68b/162d/791ce00f44c72b2eb2922358a14b1aa3?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=AG88pfnLsfVUCt2o3D-MizNd3aDawaGlgNE6jFoYVnFZVC74bHw~2e1MT8K1EqwImjLdOsyl2DBTjamIPw3cuPByt6D~oKspBUq3xRsH1lNKvOjf7D8Ii~FxHyc4Xmr7kEFmLm3esqe5RxBfLw2ir0~HQN~F9eSUyK0qV7sIOqR6os1RR4Dj97kdc2AD4LiWFE7N9hpXVXZq4y0EYdJDY1ewVUjVHV7uXREFVlzH5r6qjfw2WbehVXePibk7yzSbR8OS9Xssg2Z5S4IUaL-3eY-h3u-RwslwfG18MtDgtBQwH7KsnoTSifFebapSRsw3SmgJbqA0Jw1H1B39Z3T-eQ__' className="size-8" />,
//     competitorValueProps: [],
//     competitorWeaknesses: [],
//     differentiates: []
//   },
//   {
//     id: 3,
//     title: 'Octave',
//     website: `octavehq.com`,
//     icon: <img src='https://s3-alpha-sig.figma.com/img/2d62/c098/f930be5dfbf92c2475b062e724ddda63?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Y3Dh4N0A9zzN2S1PpP8ytsbeXpI8wvpZRXlQVAsuWMPeF3mDU6CkLN~NbxlTBV2LlEwG82wVgwG30AEtRaFoh-P5XT7cbCengTcQZzYZcJ68BnglaAk5CKLaVXcH-tJ~KpnmxGoAPHsTOuYcZBp~ti0M1tCgcSQ0WGi9j0H4FeQRIEN2ivdzSRyT8x0riE11pcsVxl1P5Y3otz-ZrbmD10jTfPsocAmpQzIeEXJodPFWlk56jehWYftMF1Ydbqo8ESeLmwpxlWGNw1GQTDzH8wnKkZtOnf0gFiAU~NDVh7kBKq8D1cnOfHa8glI~1A1-h3gCu2vj4fx0-mT1gr0eXA__' className="size-8" />,
//     competitorValueProps: [],
//     competitorWeaknesses: [],
//     differentiates: []
//   },
//   {
//     id: 4,
//     title: 'Hubspot',
//     website: `hubspot.com`,
//     icon: <img src='https://s3-alpha-sig.figma.com/img/b0ab/7ad9/06f0be7c8a8d2b5d35ca3ea87bd0aeba?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UdRn7Mx~SJa-gbSZiBgfDPLQvNiOC7YKeTdeuDBRVnR~ZTvvjp1lq8UF7-mELMIjrOi65yUwTEFwTPfGqSziFIXAs3S0WjTmxkDsFMzHuH-KevlVFon18mcPIv9ZeT5qGnPnRfyZKcBiMbmV-ApKXytqRG76fue3nrixh0lIkeOgRnS7FQNt3BlHsevQ5M1fk5gac5naVFVP88boWNV9ewo9tciwe34VyhfetX6C8y6mDM9ADAt~vnsxmIkz03UhcfvpJ6PND8APLkyjIbEb2F0Uv-HoabcL1iQH1dE9dP0IQLvSflTT~wFnMt7-K0InO9M6swk919GoyLpVyqmEfw__' className="size-8" />,
//     competitorValueProps: [],
//     competitorWeaknesses: [],
//     differentiates: []
//   },
//   {
//     id: 5,
//     title: 'Zoho CRM',
//     website: `zoho.com/crm`,
//     icon: <img src='https://s3-alpha-sig.figma.com/img/41a5/b3b5/0233ae1ef8216621f786854a428bcd4d?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Fk0hOE858NDZYUYb7uXhwIe7xWe4hgQHIwE4Lexe6gGYP0W8YS7dTP7Jev87s6vEjNqJBJIIt0jVjhd-BWFjhwW1Z53OgN7QpT52zdeWU8LcPEh8jqmfXRhgzMZ0n3Y39QiWpCs52HEyV2~h2dOv39t-HABS4xWCZhX5NDRtQdE-llvGIC451k0u3ZnCHI6Akvf1ww16vzxIWPe3GJ1v~SCI7Tv8mApX8j-EeT1oZ8u-vpWHYPxMM~i3uvm3rfKbZgKPMcTgp-K5dyl5AFUZNCaF3sD4gvAzb~n9RTluMHQBMJzGfI0ojPs~PPHrBX0dDqOOmkZLyuiesyMbNJmwhQ__' className="size-8" />,
//     competitorValueProps: [],
//     competitorWeaknesses: [],
//     differentiates: []
//   }
// ]

export default function DemoCompetitorsPage() {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [competitors, setCompetitors] = useState<ICompetitor[]>([]);
  const navigate = useNavigate();
  const onSearchCompanies = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  useEffect(() => {
    localApi.list(`/agents/orgs/x-functions/knowledgebase-context/competitors`)
      .then(res => {
        if (res.data.items.length > 0) {
          setCompetitors(res.data.items.map(item => ({
            ...item,
            icon: emptyCompetitor.icon
          })));
        }
      })
  }, []);

  const onAddCompetitor = () => {
    navigate('/settings/competitors/new');
  };
  const onEditCompetitor = (competitor: ICompetitor) => {
    navigate(`/settings/competitors/${competitor.id}`);
  };

  return (
    <main>
      <MainSidebar
        pageTitle="Competitors"
        shouldHideActionButton
        toolbar={
          <Button color="dark" className="gap-2 flex items-center">
            <img src={PlusIcon} className="w-4 h-4" />
            Add competitor
          </Button>
        }
      >
        <div className="text-cell px-8 py-5">
          <div className="flex flex-col gap-2 align-center">
            <div className="w-[250px]">
              <InputGroup>
                <MenuIcon
                  src={SearchIcon}
                  data-slot="icon"
                  className="!size-4"
                />
                <Input
                  placeholder="Search competitors"
                  className="h-9 pl-9 pr-2 !rounded-full border-none !bg-[#F2F2F2]"
                  onChange={onSearchCompanies}
                  value={searchKeyword}
                  color=''
                />
              </InputGroup>
            </div>
          </div>
          <div className="mt-5">
            {competitors.map((competitor, index) => (
              <CompetitorItem competitor={competitor} key={competitor.id} borderTop={index === 0} onClick={() => onEditCompetitor(competitor)} />
            ))}
          </div>
          <div
            className="w-full border mt-5 border-[#00000022] text-[#00000099] border-dashed rounded-lg px-4 h-[42px] flex gap-1.5 items-center text-sm cursor-pointer"
            onClick={onAddCompetitor}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.75003 3.33331C8.75003 2.9191 8.41424 2.58331 8.00003 2.58331C7.58582 2.58331 7.25003 2.9191 7.25003 3.33331V7.25H3.33337C2.91916 7.25 2.58337 7.58579 2.58337 8C2.58337 8.41421 2.91916 8.75 3.33337 8.75H7.25003V12.6666C7.25003 13.0809 7.58582 13.4166 8.00003 13.4166C8.41424 13.4166 8.75003 13.0809 8.75003 12.6666V8.75H12.6667C13.0809 8.75 13.4167 8.41421 13.4167 8C13.4167 7.58579 13.0809 7.25 12.6667 7.25H8.75003V3.33331Z"
                fill="black"
                fill-opacity="0.6"
              />
            </svg>
            <span>Add competitor</span>
          </div>
        </div>
      </MainSidebar>
    </main>
  )
}
