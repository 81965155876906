import Retool from "react-retool";
import {
  CameraIcon,
  DocIcon,
  TableIcon,
  ThunderIcon,
} from "@/components/custom/icons";
import MainSidebar from "@/components/custom/sidebar";

interface ITopic {
  id: number;
  icon: string;
  description: string;
}

const mockTopics = [
  {
    id: 0,
    icon: ThunderIcon,
    description: "Suggest actions to advanced stalled deals",
  },
  {
    id: 1,
    icon: DocIcon,
    description: "Generate personalised customer content",
  },
  {
    id: 2,
    icon: TableIcon,
    description: "Generate multi-scenario revenue forecasts",
  },
  {
    id: 3,
    icon: CameraIcon,
    description: "Optimize sales stages and processes",
  },
];

interface ITopicSectionProps {
  topic: ITopic;
}

const TopicSection = ({ topic }: ITopicSectionProps) => {
  return (
    <div className="h-[110px] bg-[#F2F2F299] p-4 font-firstext">
      <img src={topic.icon} className="size-4" />
      <div className="mt-3 text-sm">{topic.description}</div>
    </div>
  );
};

export default function DemoRetoolPage() {
  return (
    <main>
      <MainSidebar
        pageTitle="Performance"
        shouldHideActionButton
      >
        <Retool
          url="https://bloomars.retool.com/apps/5b05a9b4-96b9-11ef-a6c7-0b9fcf43c85e/Bloom%20ARS%20-%20Dashboard/Overview"
        />
      </MainSidebar>
    </main>
  );
}
