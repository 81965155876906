import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { Textarea } from "@/components/cataylst/textarea";
import { MultiTextGroup } from "@/components/custom/multi-text-group";
import { useParams } from "react-router-dom";
import { ICompetitor } from "./DemoCompetitorsPage";
import { Input, InputGroup } from "@/components/cataylst/input";
import { MenuIcon } from "@/components/custom/menu-icon";
import { useEffect, useState } from "react";
import { apiClient, localApi } from "@/services/api";
import { Environment } from "@/utils/constants";
import axios from "axios";

export const emptyCompetitor: ICompetitor = {
  id: '',
  competitorId: '',
  differentiation: '',
  icon: <img src='https://s3-alpha-sig.figma.com/img/716d/368d/533c1c6938b05acca2a75837df8e8546?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=LBDCenffa~np866xY9DL0l9kKG-y9qWnATfeAj5tbVWAKtt-zKOMqWCPztOPo9wqMDssjpZv7IvassBJAaymaUs254ySFQM3BrD~7K~2pNx-bMYaKwNa4ew5-YgJGdUsdMyOJ3Z7Qu71O9NEQh0KSIno9XsscipSr6Fwyj9YqECE--qo4FsxXpJlOOCSc8HMncdb-JsX5oM2FjQPqco5iBVR6Oy6Rj~apJJeV-3sZeBLPVQGmMo34JiM7aVWg88czkdFd1CqZEsKa333imSWfquBUBZL1BoTjPgJFKFGJFwVL1ysvYNaPMlgS7UXzHNJp~SNv4gwPZTQj9~9SvX-wg__' className="size-8" />,
  name: '',
  valuePropositions: '',
  weaknesses: '',
  website: ''
};

export default function DemoCompetitorDetailpage() {
  const { competitorId } = useParams();

  const [competitorDetails, setCompetitorDetails] = useState<ICompetitor>(emptyCompetitor);

  useEffect(() => {
    localApi.get(`/agents/orgs/x-functions/knowledgebase-context/competitors/${competitorId}`)
      .then(res => {
        setCompetitorDetails({
          ...res.data,
          icon: emptyCompetitor.icon
        });
      });
  }, [competitorId]);

  const onCompetitorDetailsSingleChange = (fieldName: string, texts: string) => {
    const newCompetitorDetails = { ...competitorDetails };
    newCompetitorDetails[fieldName] = texts;
    setCompetitorDetails(newCompetitorDetails);
  };

  const onAddKnowledgebaseFile = async () => {
    const newCompetitorDetails: ICompetitor = {
      ...competitorDetails
    }
    if (!competitorDetails.id) {
      const { data } = await localApi.post(`/agents/orgs/x-functions/knowledgebase-context/competitors`, newCompetitorDetails);
      setCompetitorDetails({
        ...data,
        icon: emptyCompetitor.icon
      });
    }
    else
      await localApi.put(`/agents/orgs/x-functions/knowledgebase-context/competitors`, newCompetitorDetails);
    const { data: newKnowledgebaseFile } = await apiClient.post(`/agents/orgs/x-functions/knowledgebases/${Environment.KNOWLEDGE_BASE_ID}/files/upload-url`, {
      fileName: `New-Competitor-Knowledgebase-File-${new Date().getTime()}.txt`,
      contentType: 'text'
    });
    const filePresignedUrl = newKnowledgebaseFile.uploadUrl;
    await axios.put(filePresignedUrl, JSON.stringify({...competitorDetails, icon: undefined}), {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
  }

  return (
    <main>
      <MainSidebar
        pageTitle={`Competitors/${competitorDetails.name || 'New'}`}
        // shouldHideActionButton
        toolbar={
          <Button color="dark" size="lg" onClick={onAddKnowledgebaseFile}>
            Save
          </Button>
        }
      >
        <div className="text-cell px-8 py-5 max-w-[852px]">
          <div className="flex flex-col gap-2">
            <div className="font-medium">Competitor website</div>
            <InputGroup>
              <MenuIcon src={"https://s3-alpha-sig.figma.com/img/b68b/162d/791ce00f44c72b2eb2922358a14b1aa3?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=AG88pfnLsfVUCt2o3D-MizNd3aDawaGlgNE6jFoYVnFZVC74bHw~2e1MT8K1EqwImjLdOsyl2DBTjamIPw3cuPByt6D~oKspBUq3xRsH1lNKvOjf7D8Ii~FxHyc4Xmr7kEFmLm3esqe5RxBfLw2ir0~HQN~F9eSUyK0qV7sIOqR6os1RR4Dj97kdc2AD4LiWFE7N9hpXVXZq4y0EYdJDY1ewVUjVHV7uXREFVlzH5r6qjfw2WbehVXePibk7yzSbR8OS9Xssg2Z5S4IUaL-3eY-h3u-RwslwfG18MtDgtBQwH7KsnoTSifFebapSRsw3SmgJbqA0Jw1H1B39Z3T-eQ__"} data-slot="icon" className="!size-4" />
              <Input placeholder="Company website" className="h-9 pl-9 pr-2 !rounded-lg" value={`${competitorDetails?.website}`} onChange={(e) => onCompetitorDetailsSingleChange('website', e.target.value)} />
            </InputGroup>
          </div>
          <div className="flex flex-col gap-2 mt-8">
            <div className="font-medium">Competitor name</div>
            <Textarea
              resizable={false}
              withItems={null}
              type="text"
              value={competitorDetails?.name}
              rows={1}
              onChange={(e) => onCompetitorDetailsSingleChange('name', e.target.value)}
            />
          </div>
          <div className="mt-8">
            <MultiTextGroup buttonName="Add value prop" label={"Competitor value props"} defaultValues={competitorDetails?.valuePropositions.split("@@@")} onChange={(texts) => onCompetitorDetailsSingleChange('valuePropositions', texts.join("@@@"))} />
          </div>
          <div className="mt-8">
            <MultiTextGroup buttonName="Add weakness" label={"Competitor weaknesses"} defaultValues={competitorDetails?.weaknesses.split("@@@")} onChange={(texts) => onCompetitorDetailsSingleChange('weaknesses', texts.join("@@@"))} />
          </div>
          <div className="mt-8">
            <MultiTextGroup buttonName="Add differentiator" label={"How Bloom differentiates"} defaultValues={competitorDetails?.differentiation.split("@@@")} onChange={(texts) => onCompetitorDetailsSingleChange('differentiation', texts.join("@@@"))} />
          </div>
          <div className="mt-20">
          </div>
        </div>
      </MainSidebar>
    </main>
  );
}
